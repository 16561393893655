import Vue from 'vue'

var baseGeneralURI = 'https://lb.sandbox.whish.money/itel-service';//sandbox lb change lb to ae to go to AE


const v = Vue.prototype;
export const TARGET_VERIFICATION = "verification";
export const TARGET_NEW_USER = "newUser";
export const baseURI = baseGeneralURI;


export const API_ENDPOINTS = {
    getCountries: `${baseGeneralURI}/global/servers`,
    getProfileData: `${v.$session.get('baseURI')}auth/accounttypes`,
    checkProfileData: `${v.$session.get('baseURI')}account/myprofile`,
    wholeSaleCheckout: `${v.$session.get('baseURI')}sale/items/deliver`,
    wholeSaleEndpoint: `sale/items/deliver`,


}
export const GLOBAL_FUNC = {
    getUid: `${([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )}`
}

// export var HEADERS = {
// 'Access-Control-Allow-Origin': 'https://ae.sandbox.whish.money/*',
// 'Access-Control-Allow-Credentials': true,
// language: 'en',
// deviceId: '',
// itelVersion: '4179',
// uid: 'uidtest1.3',
// brand: 'Web',
// model: 'Web',
// osVersion: 'unix',
// operatingSystem: '2'
// }

// last header
//   "Access-Control-Allow-Credentials": true,
//   language: this.$session.get('lang') || "en",
//       itelVersion: "1000",
//       uid: "uidtest1.3",
//       brand: "Web",
//       model: "Web",
//       osVersion: "unix",
//       operatingSystem: "4",
//       sessionId: `${this.$session.get("sessionId")}`,
//       token: `${this.$session.get("token")}`,
//       deviceId: `${this.$session.get("deviceid")}`,
//       sessionCounter: `${this.$session.get("counter")}`,
